import PageContext from '@context'
import renderBloks from '@renderBloks'
import React, { useContext } from 'react'
import SbEditable from 'storyblok-react'

const ABTestVariation = ({ blok, testId }) => {
  const { content, id } = blok
  const { isInEditor } = useContext(PageContext)

  const renderVariation = () => {
    if (isInEditor) {
      return (
        <SbEditable content={blok}>
          <div>{renderBloks(content)}</div>
        </SbEditable>
      )
    }

    return <div className={`${testId}-${id}`}>{renderBloks(content)}</div>
  }

  return content && id ? renderVariation() : null
}

export default ABTestVariation
